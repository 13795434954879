<template>
  <div class="page">
    <header class="header">送评注册表</header>

    <van-form v-if="show" style="margin-top: 20px">
      <van-field
        v-model="form.name"
        label="姓名"
        placeholder="请输入姓名"
        :rules="[{ required: true, message: '请填写姓名' }]"
      />
      <van-field
        v-model="form.tel"
        label="电话"
        placeholder="请填写联系电话"
        :rules="[{ required: true, message: '请填写联系电话' }]"
      />

      <!-- <van-field name="radio" label="送评服务">
        <template #input>
          <van-radio-group v-model="form.type" direction="horizontal">
            <van-radio :name="0">无</van-radio>
            <van-radio :name="1">评级</van-radio>
            <van-radio :name="2">重新评级</van-radio>
            <van-radio :name="3">换装</van-radio>
            <van-radio :name="4">重新装盒</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="radio" label="真品鉴定">
        <template #input>
          <van-radio-group v-model="form.service" direction="horizontal">
            <van-radio :name="0">无</van-radio>
            <van-radio :name="1">提供详情的真品鉴定</van-radio>
            <van-radio :name="2">不将真品装入封装盒</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <van-field
        v-model="form.amount"
        label="送评数量"
        type="digit"
        placeholder="请填写送评钱币数量"
        :rules="[{ required: true, message: '请填写送评钱币数量' }]"
      />

      <van-field
        v-model="form.desc"
        rows="2"
        autosize
        label="留言备注"
        type="textarea"
        placeholder="请输入留言备注信息"
      /> -->
    </van-form>
    <div v-else class="flex">
      加载中...
    </div>

    <!-- <div class="img-list">
      <div class="item" v-for="(item, index) in form.fileList" :key="index">
        <div class="container">
          <img :src="item" alt="" />
        </div>
      </div>
      <div class="item">
        <div class="container" @click="chooseImg">
          <img style="width: 44px" src="/plus.png" alt="" />
        </div>
      </div>
    </div>
    <p style="color: #e02f21; padding: 0 15px">每件请至少拍两张照片</p> -->

    <footer v-if="show" class="footer">
      <div class="item">
        <div class="button" @click="sub">提交注册</div>
      </div>
    </footer>
  </div>
</template>

<style lang='scss' scord>
.flex{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page {
  background: #f5f5f5;
  .van-radio__icon--checked .van-icon-success {
    background-color: #e02f21;
    border-color: #e02f21;
  }
}
.img-list {
  background: #fff;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  .item {
    height: 24vw;
    width: 25%;
    padding: 5px;
    .container {
      background: #f5f5f5;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>

<script>
import { Form, Field, RadioGroup, Radio } from "vant";

export default {
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
  },
  data() {
    return {
      show: false,
      err: "",
      shop_id: null,
      step: 1,
      form: {
        name: "",
        tel: "",
        desc: "",
        type: 0,
        service: 0,
        amount: null,
        fileList: [],
      },
      serviceList: [
        { id: 1, name: "罕品", max_price: "无限制", fee: 1550 },
        { id: 2, name: "高价", max_price: "62500", fee: 750 },
        { id: 3, name: "优质", max_price: "12500", fee: 560 },
        { id: 4, name: "普快**", max_price: "2000", fee: 390 },
      ],
      signature: {},
    };
  },

  created() {
    this.shop_id = this.$route.params.shop_id;
    this.getUserInfo();
  },
  async mounted() {
    localStorage.setItem("backTo", this.$route.fullPath);
    const fullPath = this.$config.domain + this.$route.fullPath;

    this.signature = await this.$axios.post("/getSignature", {
      url: fullPath,
    });
    console.log(this.signature, 1111);

    wx.config({
      debug: false,
      appId: this.signature.appId,
      timestamp: Number(this.signature.timestamp),
      nonceStr: this.signature.nonceStr,
      signature: this.signature.signature,
      jsApiList: ["chooseImage", "previewImage", "uploadImage"],
    });
  },
  methods: {
    async getUserInfo() {
      const r = await this.$axios.get("/user/getUserInfo");
      console.log(r, 22222);

      if (r.status == 1) {
        if (r.userInfo.name && r.userInfo.tel) {
          this.$router.push("/service/list/" + this.$store.state.shop_id);
        } else {
          this.show = true;
        }
      } else {
        this.Dialog.alert({
          title: "提示",
          message: "请先授权登录",
        }).then((r) => {
          this.$router.push("/login/" + this.$store.state.shop_id);
        });
      }
    },
    next(e) {
      this.step += e;
    },
    async upload() {
      let formData = new FormData();
      formData.append("files", this.form.fileList);
      const r = await this.$axios.post("/upload", this.form.fileList);
      console.log(r);
    },
    async sub() {
      if (!this.form.name) {
        return this.Dialog.alert({
          title: "提示",
          message: "请输入姓名",
        });
      }
      if (!this.form.tel) {
        return this.Dialog.alert({
          title: "提示",
          message: "请输入联系电话",
        });
      }
      // if (!this.form.type && !this.form.service) {
      //   return this.Dialog.alert({
      //     title: "提示",
      //     message: "请选择送评服务或真品鉴定",
      //   });
      // }
      // if (!this.form.amount) {
      //   return this.Dialog.alert({
      //     title: "提示",
      //     message: "请输入送评数量",
      //   });
      // }
      // this.form.shop_id = this.shop_id;
      const r = await this.$axios.post("/user/reg", this.form);
      console.log(r.id);

      if (r.status == 1) {
        this.Dialog.alert({
          title: "提示",
          message: r.msg,
        }).then(() => {
          this.$router.push("/service/list/" + this.$store.state.shop_id);
        });
      } else {
        this.Dialog.alert({
          title: "提示",
          message: r.msg,
        });
      }
    },

    chooseImg() {
      const that = this;

      wx.ready(function () {
        wx.chooseImage({
          count: 1, // 默认9
          sizeType: ["original"], // 可以指定是原图还是压缩图，默认二者都有
          sourceType: ["album", "camera"], // 可以指定来源是相册还是相机，默认二者都有
          success: function (res) {
            const localId = res.localIds[0]; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
            that.img2 = localId;
            // alert(localId)
            wx.uploadImage({
              localId: localId, // 需要上传的图片的本地ID，由chooseImage接口获得
              isShowProgressTips: 1, // 默认为1，显示进度提示
              success: async function (res2) {
                const serverId = res2.serverId; // 返回图片的服务器端ID
                // 将serverId在服务端下载图片并传回链接
                const r = await that.$axios.post("/wechat/getImgFromWx", {
                  serverId,
                });
                if (r.status == 1) that.form.fileList.push(r.file_path);
              },
              fail: function (error) {
                console.log(error, 9999999999);
              },
            });
          },
        });
      });
      wx.error(function (err) {
        console.error(err, 888877776666);
      });
    },
  },
};
</script>